import React, { useEffect, useRef, useState } from 'react'
import "@aarsteinmedia/dotlottie-player";
import { pathToLottieAnimation } from '../block-slider/utils';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';

const AnimationLayer = (props) => {

    const { animation_url, animation_speed, zIndex } = props

    return (
        <div
            className="blocks-wrapper__animation-layer"
            style={{ zIndex: zIndex }}
        >
            {
                animation_url &&
                <DotLottieReact
                    src={`${'/blockspopup/'}${animation_url}`}
                    autoplay
                    loop
                    speed={animation_speed || 1}
                />
            }
        </div>
    )
}

export default AnimationLayer