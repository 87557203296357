import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import axios from "axios";
import PopUpView from './PopUpView';

import '../../scss/components/main/popUp.scss'
import { isIterableArray } from '../block-slider/utils';
import CartCtx from '../CartCtx';



const STORE_COUNT_NAME = 'galaxy-notification-count'
const STORE_SHOWED_NAMES = 'galaxy-notification-showed-items'

const dateFormat = 'DD-MM-YYYY'

const PopUpController = (props) => {

    const { sendBody, pageSlug } = props

    const { allowPopup } = useContext(CartCtx)


    const [data, setData] = useState(null); // notification data

    const [showCountData, setShowCountData] = useState(null);


    useEffect(() => {
        const fetchCountPopUp = () => {
            axios
                .get(`${process.env.REACT_APP_API_BASE}/pages/count-pop-up`)
                .then((response) => {
                    if (isIterableArray(response.data)) {
                        const storeCount = localStorage.getItem(STORE_COUNT_NAME) ? JSON.parse(localStorage.getItem(STORE_COUNT_NAME)) : false

                        // если у пользователя есть сохраненое кол-во показов 
                        if (storeCount) {

                            const isDaysHasPassed = moment(storeCount?.date, dateFormat).isBefore(moment().add(-response.data?.[1], 'days'))

                            const count = isDaysHasPassed ? response.data?.[0] : storeCount?.count
                            setShowCountData({
                                count: count || 0,
                                days: response.data?.[1] || 0,
                                date: storeCount.date
                            })
                        }
                        else {
                            // пользователь зашел первый раз  
                            setShowCountData({
                                count: response.data?.[0] || 0,
                                days: response.data?.[1] || 0,
                                date: moment().format(dateFormat)
                            })
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
        fetchCountPopUp()
    }, []);

    useEffect(() => {
        if (allowPopup) {
            if (data === null) {
                // прошел ли срок обнуления кол-ва показов
                const isDaysHasPassed = moment(showCountData?.date, dateFormat).isBefore(moment().add(-showCountData?.days, 'days'))


                if (showCountData?.count || isDaysHasPassed) {
                    if (isDaysHasPassed) {
                        // если срок прошел - очищаем массив показанных поп-апов 
                        localStorage.setItem(STORE_SHOWED_NAMES, JSON.stringify([]))
                    }
                    fetchData()
                }
            }

        }
    }, [showCountData, data, allowPopup]);


    const getShowedName = (slug, id) => `${slug}-id-${id}`

    // забираем данные с сервера и минусуем кол-во уведомлений 
    const fetchData = () => {

        axios
            .post(`${process.env.REACT_APP_API_BASE}/pages/pop-up`, sendBody)
            .then((response) => {
                if (isIterableArray(response.data)) {

                    // массив элементов которые уже были показаны
                    const showedItems = localStorage.getItem(STORE_SHOWED_NAMES) ? JSON.parse(localStorage.getItem(STORE_SHOWED_NAMES)) : []

                    for (let index = 0; index < response.data.length; index++) {
                        const isShowed = showedItems.findIndex(i => i === getShowedName(pageSlug, response.data[index].id))

                        // если поп-ап не был показан - то показываем и делаем запись. минусуем кол-во показов 
                        if (isShowed < 0) {
                            setData(response.data[index])
                            localStorage.setItem(STORE_COUNT_NAME, JSON.stringify({
                                date: moment().format(dateFormat),
                                count: showCountData.count > 1 ? showCountData.count - 1 : 0,
                                days: showCountData.days
                            }))
                            localStorage.setItem(STORE_SHOWED_NAMES, JSON.stringify([...showedItems, getShowedName(pageSlug, response.data[index].id)]))
                            return
                        }
                        // else этот элемент уже показывали этому пользователю на этой странице
                    }
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const handleClose = () => {
        setData({})
    }

    const handleCloseAndClear = () => {
        setData({})
        localStorage.setItem(STORE_COUNT_NAME, JSON.stringify({
            date: moment().format(dateFormat),
            count: 0
        }))
    }

    if (!showCountData?.count && moment(showCountData?.date, dateFormat).isAfter(moment().add(-showCountData?.days, 'days'))) return ''

    return (
        <>
            {
                data ?
                    Object.keys(data)?.length ?
                        <PopUpView
                            handleClose={handleClose}
                            handleCloseAndClear={handleCloseAndClear}
                            data={data}
                        />
                        :
                        ''
                    :
                    ''
            }
        </>
    )
}

export default PopUpController