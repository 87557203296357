import fontColorContrast from 'font-color-contrast'
import React, { useRef, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { createPortal } from 'react-dom'
import { NavLink } from 'react-router-dom'
import Badges from '../main/Badges'
import AnimationLayer from './AnimationLayer'
import "@aarsteinmedia/dotlottie-player";

const PushNotificationView = ({ data, handleClose, handleCloseAndClear }) => {

    const { type, info: { block_exist } } = data

    const getStyleBg = (data) => {
        if (!data) return {}
        if (data.background_gradient) return {
            backgroundImage: data.background_gradient
        }
        if (data.background) return {
            background: data.background
        }
        return {}
    }





    return (
        createPortal(
            <div style={{ position: 'relative', zIndex: 1000000 }}>
                {/* <div className='push-item-overlay' onClick={handleClose} /> */}
                <div
                    className={`
                    push-item
                    ${!block_exist ? 'appear-from-right' : ''}
                    ${block_exist === 1 ? 'appear-from-right' : ''}
                    ${block_exist === 2 ? 'appear-from-left' : ''}
                `}
                    style={getStyleBg(data.info)}
                >
                    {
                        type === 1 &&
                        <NavLink
                            to={{ pathname: data.info.button.link ? data.info.button.link : '#' }}
                        >
                            {
                                data.info.animation_background &&
                                <AnimationLayer
                                    animation_url={data.info.animation_background.url}
                                    animation_speed={data.info.animation_background.speed}
                                    zIndex={-1}
                                />
                            }
                            {
                                data.info.animation_picture &&
                                <AnimationLayer
                                    animation_url={data.info.animation_picture.url}
                                    animation_speed={data.info.animation_picture.speed}
                                    zIndex={3}
                                />
                            }
                            <div className={`push-item-type-${type}`}>
                                <div className='close-button' onClick={(e) => {
                                    e.preventDefault()
                                    handleCloseAndClear()
                                }}>
                                    <i className='i-close' />
                                </div>
                                <div className='image-container'>
                                    <img
                                        src={process.env.REACT_APP_BACKEND + '/uploads/blockspopup/' + data.info.picture.url}
                                        alt={data.info.alt}
                                    />
                                </div>
                                {
                                    data.info.text &&
                                    <div className='text-container' dangerouslySetInnerHTML={{ __html: data.info.text }} />
                                }
                                <Row noGutters>
                                    <Col lg={6} className='p-3'>
                                        {
                                            data.info.badge &&
                                            <Badges
                                                items={{
                                                    bages: [{
                                                        bage: data.info.badge
                                                    }]
                                                }}
                                            />
                                        }
                                    </Col>
                                    <Col lg={6}>
                                        {
                                            data.info.button.text &&
                                            <div className='info-container'>
                                                <div
                                                    className='btn-link'
                                                    style={{
                                                        border: `2px solid ${data.info.button.background}`,
                                                        backgroundColor: data.info.button.background,
                                                        color: fontColorContrast(data.info.button.background)
                                                    }}

                                                >
                                                    <span style={{ color: fontColorContrast(data.info.button.background) }} dangerouslySetInnerHTML={{ __html: data.info.button.text }} />
                                                </div>
                                            </div>
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </NavLink>
                    }
                    {
                        type === 2 &&
                        <NavLink
                            to={{ pathname: data.info.button.link ? data.info.button.link : '#' }}
                        >
                            {
                                data.info.animation_background &&
                                <AnimationLayer
                                    animation_url={data.info.animation_background.url}
                                    animation_speed={data.info.animation_background.speed}
                                    zIndex={-1}
                                />
                            }
                            {
                                data.info.animation_picture &&
                                <AnimationLayer
                                    animation_url={data.info.animation_picture.url}
                                    animation_speed={data.info.animation_picture.speed}
                                    zIndex={3}
                                />
                            }
                            <div className={`push-item-type-${type}`}>
                                <div className='close-button' onClick={(e) => {
                                    e.preventDefault()
                                    handleCloseAndClear()
                                }}>
                                    <i className='i-close' />
                                </div>

                                {
                                    data.info.text &&
                                    <div className='text-container' dangerouslySetInnerHTML={{ __html: data.info.text }} />
                                }
                                <Row noGutters>
                                    <Col lg={6} className='p-3'>
                                        {
                                            data.info.badge &&
                                            <Badges
                                                items={{
                                                    bages: [{
                                                        bage: data.info.badge
                                                    }]
                                                }}
                                            />
                                        }
                                    </Col>
                                    <Col lg={6}>
                                        <div className='info-container'>
                                            {
                                                data.info.button.text &&
                                                <div
                                                    className='btn-link'
                                                    style={{
                                                        border: `2px solid ${data.info.button.background}`,
                                                        backgroundColor: data.info.button.background,
                                                        color: fontColorContrast(data.info.button.background)
                                                    }}

                                                >
                                                    <span style={{ color: fontColorContrast(data.info.button.background) }} dangerouslySetInnerHTML={{ __html: data.info.button.text }} />
                                                </div>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </NavLink>
                    }
                    {
                        type === 3 &&
                        <NavLink
                            to={{ pathname: data.info.button.link ? data.info.button.link : '#' }}
                        >
                            {
                                data.info.animation_background &&
                                <AnimationLayer
                                    animation_url={data.info.animation_background.url}
                                    animation_speed={data.info.animation_background.speed}
                                    zIndex={-1}
                                />
                            }
                            {
                                data.info.animation_picture &&
                                <AnimationLayer
                                    animation_url={data.info.animation_picture.url}
                                    animation_speed={data.info.animation_picture.speed}
                                    zIndex={3}
                                />
                            }
                            <div className={`push-item-type-${type}`}>
                                <div className='close-button' onClick={(e) => {
                                    e.preventDefault()
                                    handleCloseAndClear()
                                }}>
                                    <i className='i-close' />
                                </div>
                                <div className='image-container'>
                                    <video
                                        playsInline
                                        autoPlay
                                        controls={false}
                                        muted
                                        loop
                                        src={process.env.REACT_APP_BACKEND + '/uploads/blockspopup/' + data.info.picture.url}
                                    />
                                </div>
                                {
                                    data.info.text &&
                                    <div className='text-container' dangerouslySetInnerHTML={{ __html: data.info.text }} />
                                }
                                <Row noGutters>
                                    <Col lg={6} className='p-3'>
                                        {
                                            data.info.badge &&
                                            <Badges
                                                items={{
                                                    bages: [{
                                                        bage: data.info.badge
                                                    }]
                                                }}
                                            />
                                        }
                                    </Col>
                                    <Col lg={6}>
                                        {
                                            data.info.button.text &&
                                            <div className='info-container'>
                                                <div
                                                    className='btn-link'
                                                    style={{
                                                        border: `2px solid ${data.info.button.background}`,
                                                        backgroundColor: data.info.button.background,
                                                        color: fontColorContrast(data.info.button.background)
                                                    }}

                                                >
                                                    <span style={{ color: fontColorContrast(data.info.button.background) }} dangerouslySetInnerHTML={{ __html: data.info.button.text }} />
                                                </div>
                                            </div>
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </NavLink>
                    }
                    <div className='line'></div>
                </div>
            </div >,
            document.getElementById('root')
        )
    )
}

export default PushNotificationView