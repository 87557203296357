import React, { useEffect, useRef, useState } from 'react'
// import "@aarsteinmedia/dotlottie-player";
import { DotLottieReact } from '@lottiefiles/dotlottie-react';

const AnimationContainer = (props) => {

    const { animation, zIndex } = props

    return (
        <>
            <div className="animation-layer" style={{ zIndex: zIndex, pointerEvents: 'none' }}>
                {
                    animation &&
                    <DotLottieReact
                        src={`${'/newspapers/'}${animation.file}`}
                        speed={animation.speed}
                        autoplay
                        loop
                    />
                }
            </div>
            {props.children ? props.children : ''}
        </>
    )
}

export default AnimationContainer
