import React, { useEffect } from 'react'
import AnimationContainer from './components/AnimationContainer'
import JournalPromo from "./components/JournalPromo";


const pathToNewspaperImage = process.env.REACT_APP_BACKEND + '/uploads/sp/newspapers/'


export const getStylePromoBg = (data) => {
    if (!data) return {}
    if (data.fon_gradient) return {
        background: data.fon_gradient
    }
    if (data.background) return {
        background: data.background
    }
    if (data.fon_file) {
        return {
            backgroundImage: `url(${pathToNewspaperImage}${data.fon_file})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: "cover"
        }
    }
    return {}
}

export const getStylePromoPlashka = (data) => {
    if (!data.plashka) return {
    }
    if (data.plashka_gradient) return {
        background: data.plashka_gradient
    }
    if (data.plashka_background) return {
        background: data.plashka_background
    }
    return {}
}

const PromoTypeNewspaper = (props) => {

    const { newspapers, newspapers: { info }, bottom_description } = props

    useEffect(() => {
        document.querySelector('footer').style.display = 'none'
        return () => {
            document.querySelector('footer').style.display = 'block'
        };
    }, []);

    return (
        <>
            <div className="promo-content promo-newspaper-page page-journal-promo-bg" style={getStylePromoBg(info)}>
                <AnimationContainer animation={(info?.animationfon && info?.animationfon.background) ? info.animationfon : false} zIndex={-1}>
                    {
                        (info?.line_1 || info?.line_2) ?
                            <div className='page-journal-promo-text-lines'
                                style={getStylePromoPlashka(info)}
                            >
                                {
                                    info?.line_1 ?
                                        <p style={{ color: info?.line_1_color ? info.line_1_color : 'inherit' }}>
                                            {info.line_1}
                                        </p>
                                        : ''
                                }
                                {
                                    info?.line_2 ?
                                        <p style={{ color: info?.line_2_color ? info.line_2_color : 'inherit' }}>
                                            {info.line_2}
                                        </p>
                                        : ''
                                }
                            </div>
                            :
                            <div style={{ paddingTop: 30 }} />
                    }
                    {
                        newspapers &&
                        <div className='d-flex flex-center justify-content-center'>
                            <AnimationContainer animation={(info?.animation && info?.animation.background) ? info.animation : false} zIndex={1001}>
                                <JournalPromo
                                    close={false}
                                    id={newspapers.id}
                                    count={newspapers.pages}
                                    marginTop={'0px'}
                                />
                            </AnimationContainer>
                        </div>
                    }
                    <div className="promo-inner">
                        <div className="cat-desc">
                            {bottom_description && bottom_description !== 'null' && (
                                <div className="cat-desc__wrap"
                                    dangerouslySetInnerHTML={{ __html: bottom_description }} />
                            )}
                        </div>
                    </div>
                </AnimationContainer>
            </div>
        </>
    )
}

export default PromoTypeNewspaper
