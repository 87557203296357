import axios from 'axios'
import React, { useState, useEffect, useContext } from 'react'
import CartCtx from '../CartCtx';
import SliderWrapper from './SliderWrapper';
import { isIterableArray } from './utils';

const SliderFetchWrapper = (props) => {

    const { urlFetch, onSliderFetch } = props

    const [slidersData, setSlidersData] = useState([]);

    const { selectedCenter } = useContext(CartCtx)

    const fetchSlider = () => {
        // console.log('slider fetch ', urlFetch + '/' + selectedCenterId)
        const selectedCenterId = !!selectedCenter?.id ? selectedCenter.id : false

        axios.get(urlFetch + `/${selectedCenterId}`)
            .then(response => {
                // console.log('fetch response', response.data)
                if (isIterableArray(response?.data?.data)) {
                    setSlidersData(response.data.data)
                }
            })
            .catch(error => {
                console.error(error)
            })
            .finally(() => {
                if (onSliderFetch) onSliderFetch()
            })
    }

    useEffect(() => {
        fetchSlider()
    }, [urlFetch, selectedCenter?.id]);

    return (
        <div>
            <SliderWrapper data={slidersData} />
        </div>
    )
}

export default SliderFetchWrapper
